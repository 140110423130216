import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faFileSignature,
  faSpinner,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";

import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";

import { getAllProposalFilesByUser } from "../../store/reducer/proposalFiles/proposalFileSilce";
import {
  generateProposal,
  sendDocumentForSigning,
  cancelInvite,
  downloadSignedDocument,
} from "../../store/reducer/drawing/drawingProposalSlice";
import {
  drawingandproposalfiles,
  getFinalProposalFiles,
} from "../../store/reducer/drawing/drawingFileSilce";
import {
  addFile,
  deleteFile,
  isFinalFile,
} from "../../store/reducer/drawing/drawingFileSilce";
import { getCustomerSiteById } from "../../store/reducer/customers/customerSiteSlice";
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import { getCustomerById } from "../../store/reducer/customers/customersSlice";
import {  getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import { copyPOData } from "../../store/reducer/drawing/drawingPurchaseOrderSlice";
import Notes from "../Notes/Notes";

function EditDrawingProposal() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setdrawingData] = useState([]);
  const [proposalData, setProposalData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [generatedProposalData, setGeneratedProposalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [draggedObject, setDraggedObject] = useState(null);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [viewPdf, setViewPdf] = useState("");
  const [generatingProposal, setGeneratingProposal] = useState(false);
  const [reload, setReload] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [signingDocument, setSigningDocument] = useState(false);
  const [cancelInviteStatus, setCancelInviteStatus] = useState(false);
  const [customerSiteData, setCustomerSiteData] = useState([]);
  const [proposalStatus, setProposalStatus] = useState(true);
  const [emailInputs, setEmailInputs] = useState([""]); // Start with one input field
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);

  const openEmailModal = (file) => {
    setSelectedFile(file);
    setEmailModalOpen(true);
    setEmailInputs([customerData?.email])
  };
  
  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emailInputs];
    updatedEmails[index] = value;
    setEmailInputs(updatedEmails);
  };
  
  const handleAddEmailField = () => {
    setEmailInputs([...emailInputs, ""]);
  };
  
  const handleRemoveEmailField = (index) => {
    const updatedEmails = emailInputs.filter((_, i) => i !== index);
    setEmailInputs(updatedEmails);
  };
  
  const handleSendEmails = () => {
    const validEmails = emailInputs.filter((email) => email.trim() !== "");
    if (validEmails.length === 0) {
      toast.error("Please enter at least one valid email address.");
      return;
    }
  
    if (!selectedFile) {
      toast.error("No file selected for sending.");
      return;
    }
  
    if (window.confirm("Are you sure you want to send this proposal?")) {
      handleSendProposalForSigning(
        selectedFile.uniqueId,
        selectedFile.document_name,
        validEmails,
        "ketan@kuboid.co.uk"
      );
      setEmailModalOpen(false);
      toast.success("Proposal sent successfully!");
    }
  };
  
  const handleDeleteFile = async (id) => {
    await dispatch(deleteFile({ id })).then((result) => {
      if (result.payload) {
        dispatch(getFinalProposalFiles(drawing_id)).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };
  useEffect(() => {
    dispatch(getProposalFinalStatus(drawing_id)).then(result => {
      if(result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    })
    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setdrawingData(result.payload);
        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
      }
    });
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
        dispatch(getCustomerById(result.payload.customer_id)).then((result) => {
          if (result.payload) {
            setCustomerData(result.payload);
          }
        });
        dispatch(getCustomerSiteById(result.payload.site_id)).then((result) => {
          if (result.payload) {
            setCustomerSiteData(result.payload);
          }
        });
      }
    });

    dispatch(getFinalProposalFiles(drawing_id)).then((result) => {
      if (result.payload) {
        setFileData(result.payload);
      }
    });

    dispatch(
      getAllProposalFilesByUser(
        JSON.parse(localStorage.getItem("userSession")).id
      )
    ).then((result) => {
      if (result.payload && Array.isArray(result.payload)) {
        const newProposalData = result.payload.map((item) => ({
          id: item.uniqueId,
          file_name: item.display_name,
          document_name: item.document_name,
        }));

        setProposalData((prevProposalData) => [
          ...prevProposalData,
          ...newProposalData,
        ]);
      }
    });

    dispatch(drawingandproposalfiles(drawing_id)).then((result) => {
      if (result.payload && Array.isArray(result.payload)) {
        const newDrawingFiles = result.payload.map((item) => ({
          id: item.uniqueId,
          file_name: item.file_name,
          document_name: item.document_name,
        }));

        setProposalData((prevProposalData) => [
          ...prevProposalData,
          ...newDrawingFiles,
        ]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const handleIsFinal = async (
    file_id,
    drawing_id,
    document_of,
    document_type,
    status
  ) => {
    const data = {
      drawing_id,
      document_of,
      document_type,
      status
    };
    setReload(true);
    await dispatch(isFinalFile({ data, file_id })).then((result) => {
      if (result.payload) {
        dispatch(copyPOData(drawing_id));
        dispatch(getFinalProposalFiles(drawing_id)).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
            setReload(false);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const uploadToS3 = async (file, document_of, document_type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("drawing_id", drawing_id);
    formData.append("drawing_uniqueId", drawingData.uniqueId);
    formData.append("document_of", document_of);
    formData.append("document_type", document_type);
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("userSession")).id
    );

    await dispatch(addFile({ data: formData, drawing_id })).then((result) => {
      if (result.payload) {
        dispatch(getFinalProposalFiles(drawing_id)).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const handleGenerateProposal = async () => {
    try {
      setLoading(true);
      const userSession = JSON.parse(localStorage.getItem("userSession"));
      const data = {
        drawing_id: drawing_id,
        document_of: 456,
        document_type: 461,
        file_name: "Pricing Matrix",
        staff_id: userSession.id,
      };
  
      // Dispatch to generate the proposal
      const generateResult = await dispatch(
        generateProposal({ drawing_uniqueId: drawingData.uniqueId, data })
      );
  
      if (!generateResult.payload) {
        throw new Error(generateResult.error.message);
      }
  
      // Fetch all proposal files by user
      const proposalFilesResult = await dispatch(
        getAllProposalFilesByUser(userSession.id)
      );
  
      let newProposalData = [];
      if (proposalFilesResult.payload && Array.isArray(proposalFilesResult.payload)) {
        newProposalData = proposalFilesResult.payload.map((item) => ({
          id: item.uniqueId,
          file_name: item.display_name,
          document_name: item.document_name,
        }));
      }
  
      // Fetch drawing and proposal files
      const drawingFilesResult = await dispatch(drawingandproposalfiles(drawing_id));
  
      let newDrawingFiles = [];
      if (drawingFilesResult.payload && Array.isArray(drawingFilesResult.payload)) {
        newDrawingFiles = drawingFilesResult.payload.map((item) => ({
          id: item.uniqueId,
          file_name: item.file_name,
          document_name: item.document_name,
        }));
      }
  
      // Update the proposal data with new proposal and drawing files
      setProposalData([...newProposalData, ...newDrawingFiles]);
  
      // Show success message
      toast.success(generateResult.payload.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      // Ensure loading is turned off after all dispatches are fulfilled
      setLoading(false);
    }
  };
  

  const handleCloseModal = () => setIsOpen(false);

  const viewFile = (pdf) => {
    setViewPdf(pdf);
    setIsOpen(true);
  };

  const handleOnDrag = (e, object) => {
    setDraggedObject(object);
  };

  const handleOnDrop = (e) => {
    e.preventDefault();
    if (draggedObject) {
      setPdfFiles([...pdfFiles, draggedObject]);
      setDraggedObject(null);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDelete = (index) => {
    setPdfFiles(pdfFiles.filter((_, i) => i !== index));
  };

  const mergePDFs = async () => {
    setGeneratingProposal(true);
    try {
      const mergedPdf = await PDFDocument.create();

      for (const pdfFile of pdfFiles) {
        const response = await fetch(`${pdfFile.document_name}`);
        const pdfBytes = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      const mergedPdfBytes = await mergedPdf.save();

      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const file = new File([blob], "document.pdf", {
        type: "application/pdf",
      });
      const url = URL.createObjectURL(blob);

      const uploadProposal = await uploadToS3(file, 492, 491);
      setGeneratingProposal(false);
    } catch (error) {
      console.error("Error merging PDFs:", error);
      toast.error("Error Generating PDFs");
    } finally {
      setGeneratingProposal(false);
    }
  };

  const handleSendProposalForSigning = async (
    document_uniqueId,
    documentPath,
    signerEmailList,
    fromEmail
  ) => {
    setSigningDocument(true);
    const data = {
      document_uniqueId: document_uniqueId,
      documentPath: documentPath,
      signerEmailList: signerEmailList,
      fromEmail: fromEmail,
    };
    await dispatch(sendDocumentForSigning(data)).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        dispatch(getFinalProposalFiles(drawing_id)).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
          }
        });
      } else {
        toast.error(result.error.message);
      }
      setSigningDocument(false);
    });
  };

  const handleCancelInvite = async (invite_id) => {
    setCancelInviteStatus(true);
    const data = {
      invite_id: invite_id,
    };
    await dispatch(cancelInvite(data)).then((result) => {
      if (result.payload) {
        setReload(true);
        dispatch(getFinalProposalFiles(drawing_id)).then((result) => {
          if (result.payload) {
            setFileData(result.payload);
          }
        });
        setReload(false);
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
      setCancelInviteStatus(false);
    });
  };

  const handleDownloadSignedDocument = async (document_id) => {
    const data = {
      document_id: document_id,
    };
    await dispatch(downloadSignedDocument(data)).then(async (result) => {
      if (result.payload) {
        const rawPdfData = result.payload; // Assuming this is the raw PDF data string

        // Fetch the custom font
        const fontUrl = `${process.env.PUBLIC_URL}/fonts/Arial.ttf`;
        const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());

        // Create a new PDF document
        const pdfDoc = await PDFDocument.create();

        // Register fontkit
        pdfDoc.registerFontkit(fontkit);

        // Embed the custom font
        const customFont = await pdfDoc.embedFont(fontBytes);

        // Add a page
        const page = pdfDoc.addPage();

        // Draw the text with the custom font
        page.drawText(rawPdfData, {
          x: 50,
          y: 700,
          size: 12,
          font: customFont,
          color: rgb(0, 0, 0),
        });

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Create a Blob from the PDF bytes
        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element and click it to download the PDF
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "document.pdf";
        document.body.appendChild(a);
        a.click();

        // Revoke the URL
        window.URL.revokeObjectURL(url);

        toast.success("Document downloaded successfully!");
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Proposal / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Proposal
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-proposal" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
      </div>
      {!proposalStatus && !isProposalFinalised && (
        <div className="mb-10">
          <button onClick={handleGenerateProposal}>
            <div className="py-[10px] px-[40px] font-[500] font-Inter text-[18px] text-center text-[#17171B] rounded-[12px] text-[#55A14A] border border-[#55A14A]">
              <span>Generate Pricing Matrix</span>
            </div>
          </button>
        </div>
      )}
      {!loading ? (
        <>
          <div className="flex gap-[20px] mb-[30px]">
            <div className="w-[50%] bg-[#F8F8F8] rounded-[12px] p-6 items-center">
              {proposalData.map((proposal, index) => (
                <div
                  className="px-4 py-2 flex gap-[20px] w-full bg-slate-100 rounded-lg text-lg mb-4 items-center justify-between"
                  key={index}
                  draggable
                  onDragStart={(e) =>
                    handleOnDrag(e, {
                      id: proposal.id,
                      display_name: proposal.display_name,
                      file_name: proposal.file_name,
                      document_name: proposal.document_name,
                    })
                  }
                >
                  <div className="flex gap-[30px] items-center">
                    <div className="bg-red-100 p-2 rounded-full">
                      <img
                        src={process.env.PUBLIC_URL + "/icons/pdf.png"}
                        width={30}
                        alt=""
                      />
                    </div>
                    <div className="text-lg font-[400]">
                      {proposal.file_name}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div
                      className="bg-blue-100 rounded-lg p-2"
                      onClick={() => {
                        viewFile(proposal.document_name);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        size="lg"
                        style={{ color: "#397bd0" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {isOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                  <div className="bg-white rounded-lg shadow-lg p-6 max-w-[800px] w-full h-[800px]">
                    <div className="flex justify-between items-center">
                      <button onClick={handleCloseModal} className="text-xl">
                        &times;
                      </button>
                    </div>
                    {viewPdf && (
                      <iframe
                        src={viewPdf}
                        className="w-full h-[700px] mt-4 border"
                        title="PDF Viewer"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className="w-[50%] bg-[#F8F8F8] rounded-[12px] p-6 items-center text-center overflow-scroll"
              onDrop={handleOnDrop}
              onDragOver={handleDragOver}
            >
              {pdfFiles.length === 0 ? (
                <div className="text-center m-auto text-xl font-[400] ">
                  Drag files from left and drop here to create a proposal
                </div>
              ) : (
                pdfFiles.map((pdfFile, index) => (
                  <div
                    className=" w-full bg-slate-300 rounded-lg text-lg mb-4"
                    key={index}
                  >
                    <div className="px-4 py-2 flex gap-[20px] w-full bg-slate-100 rounded-lg text-lg mb-4 items-center justify-between">
                      <div className="flex gap-[30px] items-center">
                        <div className="bg-red-100 p-2 rounded-full">
                          <img
                            src={process.env.PUBLIC_URL + "/icons/pdf.png"}
                            width={30}
                            alt=""
                          />
                        </div>
                        <div className="text-lg font-[400]">
                          {pdfFile.file_name}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <button
                          className="bg-red-100 rounded-lg"
                          onClick={() => handleDelete(index)}
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/icons/delete.png"}
                            width={50}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {pdfFiles.length > 1 ? (
                <button
                  className={`mt-4 py-[10px] px-[40px] font-[500] font-Inter text-[18px] text-center rounded-[12px] border  ${
                    generatingProposal
                      ? "text-gray-300 border-gray-400"
                      : "border-[#55A14A] text-[#55A14A]"
                  }`}
                  disabled={generatingProposal}
                  onClick={mergePDFs}
                >
                  <span>
                    {generatingProposal ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        size="xl"
                        style={{ color: "#969696" }}
                        spin
                      />
                    ) : (
                      "Generate Proposal Document"
                    )}
                  </span>
                </button>
              ) : null}
            </div>
          </div>

          <div className="p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
            <div className="flex flex-col">
              <div className="sm:rounded-lg mt-5">
                <table className="w-full">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Document Of
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Document Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Generated By
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Generated On
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Is Final
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {!reload ? (
                      fileData.map((file, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                            {file.document_of_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {file.document_type_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {file.uploaded_by}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {file.createdAt}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            <input
                              type="checkbox"
                              className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                              checked={file.is_final_drawing_doc}
                              onChange={(e) => {
                                const file_id = file.id;
                                handleIsFinal(
                                  file_id,
                                  drawing_id,
                                  file.document_of,
                                  file.document_type,
                                  e.target.checked
                                );
                              }}
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {file.status}
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap text-right font-medium">
                            <div className="flex gap-[10px] items-center justify-end">
                              <span className="cursor-pointer w-[40px] bg-blue-100 rounded-lg p-2">
                                <FontAwesomeIcon
                                  icon={faEye}
                                  size="lg"
                                  style={{ color: "#397bd0" }}
                                  onClick={() => {
                                    viewFile(file.document_name);
                                  }}
                                />
                              </span>
                              {file.is_final_drawing_doc &&
                              (!file.status || file.status === "Cancelled") ? (
                                <span className="cursor-pointer w-[40px] bg-green-100 rounded-lg p-3">
                                  {signingDocument ? (
                                    <FontAwesomeIcon
                                      icon={faSpinner}
                                      size="lg"
                                      style={{ color: "#55A14A" }}
                                      spin
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faFileSignature}
                                      size="lg"
                                      color="green"
                                      onClick={() => openEmailModal(file)}
                                    />
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                              {file.is_final_drawing_doc &&
                              file.status === "Sent" ? (
                                <span className="cursor-pointer w-[40px] bg-red-100 rounded-lg p-3">
                                  {cancelInviteStatus ? (
                                    <FontAwesomeIcon
                                      icon={faSpinner}
                                      size="lg"
                                      style={{ color: "#ff0000" }}
                                      spin
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faXmarkSquare}
                                      size="lg"
                                      color="red"
                                      onClick={() => {
                                        handleCancelInvite(file.attachment_key);
                                      }}
                                    />
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                              {file.is_final_drawing_doc &&
                              file.status === "Signed" ? (
                                <span className="cursor-pointer w-[40px] bg-blue-100 rounded-lg p-2">
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    size="lg"
                                    style={{ color: "#001c40" }}
                                    onClick={() => {
                                      handleDownloadSignedDocument(
                                        file.signnow_file_id
                                      );
                                    }}
                                  />
                                </span>
                              ) : (
                                ""
                              )}

                              {!isProposalFinalised && (
                                <span className="cursor-pointer">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/icons/delete.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-red-100 rounded-lg"
                                    onClick={() => {
                                      handleDeleteFile(file.id);
                                    }}
                                  />
                                </span>
                              )}
                            </div>
                            {isOpen && (
                              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                                <div className="bg-white rounded-lg shadow-lg p-6 max-w-[800px] w-full h-[800px]">
                                  <div className="flex justify-between items-center">
                                    <button
                                      onClick={handleCloseModal}
                                      className="text-xl"
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  {viewPdf && (
                                    <iframe
                                      src={viewPdf}
                                      className="w-full h-[700px] mt-4 border"
                                      title="PDF Viewer"
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colspan="6">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            size="2xl"
                            style={{ color: "#55A14A" }}
                            spin
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {emailModalOpen && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                <div className="bg-white rounded-lg shadow-lg p-6 max-w-[500px] w-full">
                  <h2 className="text-xl font-bold mb-4">Enter Email Addresses</h2>
                  <div className="space-y-3">
                    {emailInputs.map((email, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <input
                          type="email"
                          className="w-full p-2 border rounded"
                          placeholder="Enter email address"
                          value={email}
                          onChange={(e) => handleEmailChange(index, e.target.value)}
                        />
                        {index > 0 && (
                          <button
                            className="bg-red-500 text-white px-2 py-1 rounded"
                            onClick={() => handleRemoveEmailField(index)}
                          >
                            -
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                  <button
                    className="bg-green-700 text-white px-4 py-2 rounded mt-3"
                    onClick={handleAddEmailField}
                  >
                    + Add Another
                  </button>
                  <div className="flex justify-end gap-4 mt-4">
                    <button
                      className="bg-gray-500 text-white px-4 py-2 rounded"
                      onClick={() => setEmailModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-black text-white px-4 py-2 rounded"
                      onClick={handleSendEmails}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            )}

          </div>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditDrawingProposal;
