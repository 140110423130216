import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getInstallItems
} from "../../store/reducer/drawing/drawingPurchaseOrderSlice";

const SupplierItems = ({ purchaseOrderObject, supplier, handleItemAssign, items, areaId }) => (
    <div className="sm:rounded-lg mt-5 overflow-y-auto max-h-[400px]">
      <table className="w-full">
        <thead className="bg-white">
          <tr className="rounded-[12px]">
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Assign
              <input
                type="checkbox"
                className="w-5 h-5 m-1 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                key={`${supplier?.supplier_id}`}
                onChange={(e) => {
                  items?.filter(item => !purchaseOrderObject?.assignedItems?.installs?.find(it => it.supplier?.supplier_id !== supplier?.supplier_id && it.item.id === item.id)).forEach(item => {
                    handleItemAssign(item, { supplier_id: supplier?.supplier_id, supplier_name: supplier.supplier_name}, e.target.checked)
                  })
                }}
                checked={items?.filter(
                  item => !purchaseOrderObject?.assignedItems?.installs?.find(it => it.item.id === item.id)
                )?.length == 0}
              />
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Item name
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Quantity
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
          {items?.length > 0 ? (
            items?.filter(item => !purchaseOrderObject?.assignedItems?.installs?.find(it => it.supplier?.supplier_id != supplier?.supplier_id && it.item.id == item.id)).map((item, index) => (
              <tr key={item.id + supplier?.supplier_id}>
                <input
                    type="checkbox"
                    className="w-5 h-5 mt-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                    key={`${item.id}-${supplier?.supplier_id}`}
                    onChange={(e) => handleItemAssign(item, { supplier_id: supplier?.supplier_id, supplier_name: supplier.supplier_name }, e.target.checked)}
                    checked={!!purchaseOrderObject['installs'][supplier?.supplier_id]?.items?.find(it => it.id == item.id)}
                  />
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                  {item.item_description}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                  {item.qty}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                  {item.total_cost}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="6">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="2xl"
                  style={{ color: "#55A14A" }}
                  spin
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
)

function PurchaseOrdersInstall({ itemGroup, areaField, supplier, drawing_id, purchaseOrderObject, setPurchaseOrderObject }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [installItems, setInstallItems] = useState([]);
  
  const handleItemAssign = (item, supplier, assign) => {
    setPurchaseOrderObject((prevState) => {
      const updatedInstall = { ...prevState.installs };
      let updatedInstallAssignedItems = [...prevState.assignedItems?.installs];
      if (assign) {
        if (updatedInstall[supplier?.supplier_id]) {
          if (!updatedInstall[supplier?.supplier_id].items.some((it) => it.id === item.id)) {
            updatedInstall[supplier?.supplier_id] = {
              ...updatedInstall[supplier?.supplier_id],
              items: [...updatedInstall[supplier?.supplier_id].items, item]
            };
          }
        } else {
          updatedInstall[supplier?.supplier_id] = { supplier, items: [item] };
        }
        if (!updatedInstallAssignedItems.some((it) => it.item.id === item.id)) {
          updatedInstallAssignedItems.push({ item, supplier });
        }
      } else {
        if (updatedInstall[supplier?.supplier_id]) {
          const updatedItems = updatedInstall[supplier?.supplier_id].items.filter(
            (it) => it.id !== item.id
          );
          if (updatedItems.length > 0) {
            updatedInstall[supplier?.supplier_id] = {
              ...updatedInstall[supplier?.supplier_id],
              items: updatedItems
            };
          } else {
            delete updatedInstall[supplier?.supplier_id];
          }
        }
        updatedInstallAssignedItems = updatedInstallAssignedItems.filter(
          (it) => it.item.id !== item.id
        );
      }
      return {
        ...prevState,
        installs: updatedInstall,
        assignedItems: {...prevState.assignedItems, installs: updatedInstallAssignedItems }
      };
    });
  };
  

  useEffect(() => {
    dispatch(getInstallItems(drawing_id)).then((result) => {
      if (result.payload) {
        let itemsData = result.payload;
        console.log(result.payload)
        if (supplier?.supplier_id) {
          // If areaField is selected, further filter by area_id
          if (areaField) {
            itemsData = itemsData.filter((data) => data.area_id == areaField);
          }
      
          // Aggregate items across all areas
          const aggregatedItems = itemsData.flatMap((data) => data.items_provided).reduce((acc, current) => {
            const key = `${current.item_id}`;
      
            if (!acc[key]) {
              acc[key] = {
                ...current,
                qty: 0,
                total_cost: 0,
                groupedInstalls: []
              };
            }
      
            acc[key].qty += current.qty;
            acc[key].total_cost += current.total_cost;
            acc[key].qty = parseFloat(acc[key].qty).toFixed(2);
            acc[key].total_cost = parseFloat(acc[key].total_cost).toFixed(2);
            acc[key].groupedInstalls?.push(current.id)
            
            return acc;
          }, {});
      
          // Convert the object back to an array
          const aggregatedItemsArray = Object.values(aggregatedItems);
        
          setInstallItems(aggregatedItemsArray);
        }
      }
    });
  }, [supplier, areaField, itemGroup]);
  
  return (
    <>
      {!loading ? (
        <>
        <div className="flex gap-[20px] mb-[10px]">
        <div className="bg-[#F8F8F8] w-full rounded-[12px] p-4 items-center">
          {supplier?.supplier_id ? 
            <SupplierItems 
              handleItemAssign={handleItemAssign}
              supplier={supplier}
              purchaseOrderObject={purchaseOrderObject}
              items={installItems}
              areaId={areaField}
            />
            : null
          }
        </div>
        </div>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default PurchaseOrdersInstall;
