import React, {
  useEffect,
  useState,
  useCallback,
  Suspense,
  useTransition,
} from "react";
import { useDispatch } from "react-redux";
import { getBomItems } from "../../store/reducer/drawing/drawingPurchaseOrderSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// Lazy load the child component (BomItemsForPO)
const SupplierItems = React.lazy(() => import("./BomItemsForPO"));

function PurchaseOrdersBOM({
  areaField,
  siteData,
  supplier,
  drawing_id,
  purchaseOrderObject,
  setPurchaseOrderObject,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [bomData, setBomData] = useState([]);
  const [isPending, startTransition] = useTransition();
  const refreshBomData = () => {
    if (!supplier?.supplier_id) return;
    setLoading(true);
    dispatch(getBomItems({ drawing_id, supplier_id: supplier.supplier_id })).then(
      (result) => {
        if (result.payload) {
          let itemsData = result.payload;
          if (areaField) {
            itemsData = itemsData.filter((bom) => bom.area_id == areaField);
          }
          // Aggregate items across all areas
          const aggregatedItems = itemsData.reduce((acc, current) => {
            if (!current) return acc;
            const key = `${current.item_id}-${current.extra_details?.length ? current.extra_details : ""}`;
          
            if (!acc[key]) {
              acc[key] = {
                ...current,
                qty: 0,
                revised_qty: 0,
                total_line_cost: 0,
                groupedBOM: [],
              };
            } else {
              acc[key].area_name = "Multiple"
            }
            
            // Ensure these are treated as numbers before summing
            acc[key].qty += Number(current.qty) || 0;
            acc[key].revised_qty += Number(current.revised_qty) || 0;
            acc[key].total_line_cost += Number(current.total_line_cost) || 0;
            
            acc[key].groupedBOM?.push(current.id);
            return acc;
          }, {});
          
          // Convert to array after aggregation
          const aggregatedItemsArray = Object.values(aggregatedItems).map(item => ({
            ...item,
            qty: parseFloat(item.qty).toFixed(2),
            revised_qty: parseFloat(item.revised_qty).toFixed(2),
            total_line_cost: parseFloat(item.total_line_cost).toFixed(2),
          }));
          
          setBomData(aggregatedItemsArray);          
        }
        setLoading(false);
      }
    );
  };  

  useEffect(() => {
    refreshBomData()
  }, [supplier?.supplier_id, drawing_id, areaField])

  const handleItemAssign = (item, supplier, assign) => {
    setPurchaseOrderObject((prevState) => {
      const updatedBom = { ...prevState.bom };
      let updatedBomAssignedItems = [...(prevState.assignedItems?.bom || [])];
  
      if (assign) {
        if (!updatedBom[supplier?.supplier_id]) {
          updatedBom[supplier?.supplier_id] = { supplier, items: [item] };
        } else if (
          !updatedBom[supplier?.supplier_id].items.some(
            (it) => it.id === item.id
          )
        ) {
          updatedBom[supplier?.supplier_id].items.push(item);
        }
        if (!updatedBomAssignedItems.some((it) => it.item.id === item.id)) {
          updatedBomAssignedItems.push({ item, supplier });
        }
      } else {
        if (updatedBom[supplier?.supplier_id]) {
          updatedBom[supplier?.supplier_id].items = updatedBom[supplier?.supplier_id].items.filter(
            (it) => it.id !== item.id
          );
  
          // If no items left for this supplier, remove the supplier entry
          if (updatedBom[supplier?.supplier_id].items.length === 0) {
            delete updatedBom[supplier?.supplier_id];
          }
        }
  
        updatedBomAssignedItems = updatedBomAssignedItems.filter(
          (it) => it.item.id !== item.id
        );
      }
  
      return {
        ...prevState,
        bom: updatedBom,
        assignedItems: { ...prevState.assignedItems, bom: updatedBomAssignedItems.length > 0 ? updatedBomAssignedItems : undefined },
      };
    });
  };
  

  return (
    <>
      {!loading ? (
        <div className="flex gap-[20px] mb-[10px]">
          <div className="bg-[#F8F8F8] w-full rounded-[12px] p-4 items-center">
            {supplier?.supplier_id && !isPending ? (
              <Suspense fallback={<div>Loading Supplier Items...</div>}>
                <SupplierItems
                  handleItemAssign={handleItemAssign}
                  supplier={supplier}
                  purchaseOrderObject={purchaseOrderObject}
                  bomData={bomData}
                  refreshBomData={refreshBomData}  // Passing the refresh callback
                  drawing_id={drawing_id}
                  siteData={siteData}
                />
              </Suspense>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
        </div>
      )}
    </>
  );
}

export default PurchaseOrdersBOM;
